import { useUser } from 'app/utils/useUser'
import { useRouter } from 'next/router'
import posthog from 'posthog-js'
import { useEffect } from 'react'

export const usePageView = () => {
  const router = useRouter()
  const { user } = useUser()

  useEffect(() => {
    const handleRouteChange = () => {
      posthog?.capture('$pageview', {
        $user_id: user?.id,
      })
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router, user?.id])
}
