// pages/_app.js
import posthog from 'posthog-js'
import { PostHogProvider as PostHogProviderOg } from 'posthog-js/react'

import { usePageView } from './hooks/usePageView'

if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'development') {
  // checks that we are client-side
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY!, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST! || 'https://us.i.posthog.com',
    person_profiles: 'identified_only', // or 'always' to create profiles for anonymous users as well
    loaded: (posthog) => {
      if (process.env.NODE_ENV === 'development') posthog.debug() // debug mode in development
    },
  })
}

const PostHogInner = ({ children }: { children: React.ReactNode }) => {
  usePageView()
  return children
}

export function PostHogProvider({ children }: { children: React.ReactNode }) {
  return (
    <>
      <PostHogProviderOg client={posthog}>
        <PostHogInner>{children}</PostHogInner>
      </PostHogProviderOg>
    </>
  )
}
