import { useMount } from '@legendapp/state/react'
import { createBrowserClient } from '@supabase/ssr'
import { Session } from '@supabase/supabase-js'
import {
  COOKIE_INVITER_ID,
  COOKIE_SIGNUP_SOURCE,
  ONE_WEEK_EXPIRE_TIME,
} from 'app/utils/constants/cookie'
import { SessionContextProvider } from 'app/utils/supabase/SessionContext'
import Cookies from 'js-cookie'
import { useState } from 'react'

import { AuthStateChangeHandler } from './AuthStateChangeHandler'

export type AuthProviderProps = {
  initialSession?: Session | null
  children?: React.ReactNode
}

export const AuthProvider = ({ initialSession, children }: AuthProviderProps) => {
  // Create a new supabase browser client on every first render.
  const [supabaseClient] = useState(() =>
    createBrowserClient(
      process.env.NEXT_PUBLIC_SUPABASE_URL!,
      process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY!
    )
  )

  useMount(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const inviterId = searchParams.get(COOKIE_INVITER_ID)
    const signupSource = searchParams.get('source') || searchParams.get('sourceId')
    if (inviterId) {
      Cookies.set(COOKIE_INVITER_ID, inviterId, { expires: ONE_WEEK_EXPIRE_TIME })
    }
    if (signupSource) {
      Cookies.set(COOKIE_SIGNUP_SOURCE, signupSource, {
        expires: ONE_WEEK_EXPIRE_TIME,
      })
    }
  })

  return (
    <SessionContextProvider supabaseClient={supabaseClient} initialSession={initialSession}>
      <AuthStateChangeHandler />
      {children}
    </SessionContextProvider>
  )
}
